<template>
  <div class="carBidRecord">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <v-dialog v-model="bidderInfoDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Dealer name: {{ bidderInfo.dealerName }}
        </v-card-title>
        <br />
        <v-card-text>
          <!-- <img :src="bidderInfo.carDefaultImg" /> -->
          <p>Account Type: {{ bidderInfo.accType }}</p>
          <p>Name: {{ bidderInfo.name }}</p>
          <p>Email: {{ bidderInfo.email }}</p>
          <p>Phone: {{ bidderInfo.phone }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this bid record:
          <b>{{ delCarBidRecord.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCarBidRecord()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="carBidRecords"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.productTitle }}</td>
          <td>{{ row.item.operationType }}</td>
          <td>{{ row.item.bidderInfo.dealerName }}</td>
          <td>${{ row.item.bidPrice }}</td>
          <td>${{ row.item.additionalPrice }}</td>
          <td>{{ new Date(row.item.createTime).toString() }}</td>
          <!-- tbl row bidder Info btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="showBidderInfo(row.item)"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      carBidRecords: [],
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      delCarBidRecord: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Product Title", value: "productTitle" },
        { text: "Operation Type", value: "operationType" },
        { text: "Dealer Name", value: "dealerName" },
        { text: "Bid Price", value: "bidPrice" },
        { text: "Additional Price", value: "additionalPrice" },
        { text: "Bidded Time", value: "createTime" },
        { text: "Bidder Info", sortable: false },
        { text: "Delete", sortable: false },
      ],
      bidderInfo: {
        accType: null,
        dealerId: null,
        dealerName: null,
        dealerPhone: null,
        email: null,
        name: null,
        phone: null,
      },
      bidderInfoDialog: false,
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarBidRecord();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName;
      if (sortBy[0] === "productTitle") {
        columnName = "bidding.car.productTitle";
      } else {
        columnName = sortBy[0];
      }
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = "createTime,desc";
      }
      this.fetchCarBidRecord();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarBidRecord();
    },
    delButtonClick(item) {
      this.delCarBidRecord.id = item.id;
      this.delCarBidRecord.name = item.productTitle;
      this.deleteDialog = true;
    },
    showBidderInfo(item) {
      this.bidderInfo.accType = item.bidderInfo.accType;
      this.bidderInfo.dealerName = item.bidderInfo.dealerName;
      this.bidderInfo.dealerPhone = item.bidderInfo.dealerPhone;
      this.bidderInfo.email = item.bidderInfo.email;
      this.bidderInfo.name = item.bidderInfo.name;
      this.bidderInfo.phone = item.bidderInfo.phone;
      this.bidderInfoDialog = true;
    },
    doDelCarBidRecord() {
      const self = this;
      const param = {
        carBidRecordId: self.delCarBidRecord.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/delCarBidRecord`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchCarBidRecord();
            self.showAlert("success", "Bid Record Deleted Successfully!");
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    searchType() {
      this.fetchCarBidRecord();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchCarBidRecord() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        biddingId: self.nameSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/biddingSystem/carBidRecord`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.carBidRecords = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
